import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/wherePT.jpg"

function ParkingTower() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap parkingTWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap parkingTOverview">
          <div className="textWrap">
            <h2>Parking tower</h2>
            <p className="bodyText">
              The parking tower market is a hot potato worldwide. With the
              ever-increasing number of vehicles and limited parking spaces, the
              demand for parking towers continues to increase. However, customer
              satisfaction is not high due to frequent accidents and
              one-dimensional flow of goods in and out. <br />
              <br />
              You can create a variety of customer satisfaction services by
              using ELSA Platform's real-time monitoring and control functions,
              as well as its immediate failure notification (mobile). Explore a
              new parking tower market that you've never dreamed of before, such
              as immediate dispatch in case of a breakdown in the tower and the
              launch of a new service using control functions of control.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ParkingTower
